import jQuery from './jquery-1.12.4.min.js';
import util from './util';

// home carousel
jQuery(function($) {
  $(".head-carousel").each(function() {
    var items=$(this).find(".hc-items>li");
    var hcNav=$(this).find(".hc-nav>li");
    var hcLinks=$(this).find(".hc-nav>li>a");
    var currentItem=Math.floor(Math.random()*hcLinks.length);
    var autoNext=null;
    var updateItemPos=function() {
      items.css("visibility", "visible").each(function(index) {
        $(this).css("left", (index-currentItem)*100+"%");
      }).removeClass("active").eq(currentItem).addClass("active");
      hcNav.removeClass("active").eq(currentItem).addClass("active");
      clearTimeout(autoNext);
      autoNext=setTimeout(()=>{
        arrowRight.click();
      }, 5000);
    }
    updateItemPos();
    hcLinks.click(function(e) {
      e.preventDefault();
      currentItem=hcLinks.index(this);
      updateItemPos();
    });
    
    util.detectTouchSwipe(this, function() {
      currentItem=(currentItem+1)%items.length;
      updateItemPos();
    }, function() {
      currentItem=(currentItem+items.length-1)%items.length;
      updateItemPos();
    });
  
    
    var arrowLeft=$("<a />", {href: "", "class": "nav prev"}).appendTo(this).click(function(e) {
      e.preventDefault();
      currentItem=(currentItem+items.length-1)%items.length;
      updateItemPos();
    });
    var arrowRight=$("<a />", {href: "", "class": "nav next"}).appendTo(this).click(function(e) {
      e.preventDefault();
      currentItem=(currentItem+1)%items.length;
      updateItemPos();
    });
    
    
    
  });
});


// counting
jQuery(function($) {
  var items=$("[data-count]");
  var $window=$(window);
  $window.on("scroll.counter", function() {
    var winTop=$window.scrollTop();
    var winHeight=$window.innerHeight();
    items.each(function() {
      var $this=$(this);
      if ($this.data("rendered"))
        return;
      var itemTop=$this.offset().top;
      if (itemTop<winTop+winHeight*0.9) {
        $this.data("rendered", true);
        var currentCount=0;
        var countTo=$this.attr("data-count")*1;
        var nextFrame=function() {
          currentCount=Math.ceil(currentCount+(countTo-currentCount)*0.3);
          $this.html(currentCount);
          if (currentCount<countTo)
            setTimeout(nextFrame, 100);
        };
        setTimeout(nextFrame, 200);
      }
    });
  }).trigger("scroll.counter");
  
});


// carousel click
jQuery(function($) {
  $(".hc-items li").click(function(e) {
    var link=$(this).find("a.btn-more");
    if (link.length)
      window.open(link.attr("href") || location.href, "_self");
  });
});


// news click
jQuery(function($) {
  $(".home-news ul li").click(function(e) {
    var link=$(this).find("a.lnk-more");
    if (link.length)
      window.open(link.attr("href") || location.href, "_self");
  });
});



// jedinstvena visina elemenata na najnovije usluge i najcesca pitanja
jQuery(function($) {
  var maxHeight=0;
  $(".home-stat h3").each(function() {
    maxHeight=Math.max(maxHeight, $(this).innerHeight());
  }).css("min-height", maxHeight);
  
});


jQuery(function($) {
  $(".foot-banners").each(function() {
    var $this=$(this);
    var prev=$("<a />", {href: "", "class": "nav prev"});
    var next=$("<a />", {href: "", "class": "nav next"});
    $this.append(prev).append(next);
    var current=0;
    setInterval(function() {
      current=(current+1)%($this.find("li").length-2);
      $this.find(".container").animate({scrollLeft: current*410}, 300);
    }, 5000);
    prev.click(function(e) {
      e.preventDefault();
      current=(current+$this.find("li").length-3)%($this.find("li").length-2);
      $this.find(".container").animate({scrollLeft: current*410}, 300);
    });
    next.click(function(e) {
      e.preventDefault();
      current=(current+1)%($this.find("li").length-2);
      $this.find(".container").animate({scrollLeft: current*410}, 300);
    });
  });
});

jQuery(function($) {
  $(".home-news").each(function() {
    var $this=$(this);
    if ($this.find("li").length<=3 || window.innerWidth<768)
      return;
    $this.addClass("scrolled");
    var prev=$("<a />", {href: "", "class": "nav prev"});
    var next=$("<a />", {href: "", "class": "nav next"});
    $this.append(prev).append(next);
    var current=0;
    var updatePosition=function() {
      var p=-33.333*current;
      var q=6*current;
      $this.find("li:first-child").css("margin-left", "calc("+p+"% - "+q+"px)");
    };
    setInterval(function() {
      current=(current+1)%($this.find("li").length-2);
      updatePosition();
    }, 5000);
    prev.click(function(e) {
      e.preventDefault();
      current=(current+$this.find("li").length-3)%($this.find("li").length-2);
      updatePosition();
    });
    next.click(function(e) {
      e.preventDefault();
      current=(current+1)%($this.find("li").length-2);
      updatePosition();
    });
  });
});
