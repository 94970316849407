import jQuery from './jquery-1.12.4.min.js';

jQuery(function($) {
  
  $("ul.accordion").each(function() {
    var list=$(this);
    var items=list.find(">li");
    var heads=list.find(">li>.head");
    heads.click(function() {
      var index=heads.index(this);
      if (items.eq(index).hasClass("open")) {
        items.eq(index).find(">.content").css("display", "block");
        items.eq(index).find(">.content").slideUp(300);
        setTimeout(function() {
          items.eq(index).removeClass("open");
        }, 30);
      } else {
        items.filter(".open").find(">.content").css("display", "block");
        items.filter(".open").find(">.content").slideUp(300);
        items.eq(index).find(">.content").css("display", "none");
        items.eq(index).find(">.content").slideDown(300);
        setTimeout(function() {
          items.filter(".open").removeClass("open");
          items.eq(index).addClass("open");
        }, 16);
        setTimeout(function() {
          var elTop=items.eq(index).offset().top;            
          $("html,body").animate({scrollTop: elTop-112-10}, 300);
        }, 300);
      }
    });
  });
  
  $(".home-sear .hs-box").each(function() {
    var $this=$(this);
    $this.find(">input").on("input", function(e) {
      if (this.value.length>=3) {
        $this.find("button").removeAttr("disabled");
        $this.addClass("qs-active");
      } else {
        if (this.value.length)
          $this.find("button").attr("disabled", "disabled");
//         else
//           $this.find("button").removeAttr("disabled");
        $this.removeClass("qs-active");
      }
    }).trigger("input");
  });
  $(".home-sear .hs-box button").attr("disabled", "disabled");
  
  var $window=$(window);
  var contentSections=$(".article-content h2");
  var navHeading=$(".content-nav h2");
  var csIndex=0;
  navHeading.html(contentSections.eq(0).html());
  $window.on("scroll.content", function(e) {
    var top=$window.scrollTop();
    var height=$window.innerHeight();
    contentSections.each(function(i) {
      var elTop=$(this).offset().top;
      if (elTop<=top+112+30) {
        navHeading.html($(this).html());
        csIndex=i;
      }
    });
    console.log(csIndex);
  }).on("resize.content", function(e) {
    $window.trigger("scroll.content");
  }).trigger("scroll.content");
  $(".content-nav a").click(function(e) {
    e.preventDefault();
    if ($(this).hasClass("next")) {
      if (csIndex<contentSections.length-1)
        csIndex++;
    } else {
      if (csIndex)
        csIndex--;
    }
    var elTop=contentSections.eq(csIndex).offset().top;
    $("html,body").animate({scrollTop: elTop-112-10}, 300);
  });
  
  
  
  $(".video-container a").click(function(e) {
    e.preventDefault();
    $(this).closest(".video-container").addClass("playing");
    var iframe=$(this).closest(".video-container").find("iframe");
    iframe.attr("src", iframe.attr("data-src"));
  });
  
  
  var scrollToCurrentAnchor=function() {
    var currentAnchor=null;
    $("a[name]").each(function() {
      if ("#"+$(this).attr("name")==decodeURI(location.hash)) {
        currentAnchor=$(this);
      }
    });
    if (currentAnchor) {
      if (currentAnchor.closest("ul.accordion").length) {
        if (currentAnchor.closest("ul.accordion").find(">li.open").length) {
          currentAnchor.closest("ul.accordion").find(">li.open>.head").click();
          setTimeout(()=>{
            $("html,body").scrollTop(currentAnchor.offset().top-100);
            currentAnchor.click();
          }, 300);
          setTimeout(()=>{
            $("html,body").scrollTop(currentAnchor.offset().top-100);
          }, 600);
        } else {
          $("html,body").scrollTop(currentAnchor.offset().top-100);
          currentAnchor.click();
          setTimeout(()=>{
            $("html,body").scrollTop(currentAnchor.offset().top-100);
          }, 300);
        }
      } else {
        $("html,body").scrollTop(currentAnchor.offset().top-100);
      }
    }
  }
  scrollToCurrentAnchor();
  $(window).on("hashchange", scrollToCurrentAnchor);
  $(document).on("click", "a[href]", function(e) {
    var anchor=this.href.replace(/^[^#]*/, "");
    if (anchor)
      setTimeout(function() {
        scrollToCurrentAnchor();
      }, 16);
  });
  
  $(window).on("resize.eusluga", function() {
    if ($(window).innerWidth()<=990 && $("aside.clist>div>ul.single>li.active, aside.clist>ul.single>li.active").length) {
//       $(".foot-top").hide();
      if (!$(".foot-button").length) {
        var euslugaContainer=$("<div />", {"class": "foot-button"});
        $(".foot-top").before(euslugaContainer);
        var content=$("aside.clist>div>ul.single>li.active, aside.clist>ul.single>li.active").clone();
        euslugaContainer.append(content);
        $(window).trigger("scroll");
      } else
        $(".foot-button").show();
    } else {
//       $(".foot-top").show();
      $(".foot-button").hide();
    }
    
    
    
    if ($(window).innerWidth()<=990 && $("aside.clist>ul>li").length>1) {
      if (!$(".sub-header").length) {
        var subHeader=$("<div />", {"class": "sub-header"});
        subHeader.append("<div class=\"bot-bar\"></div>");
        $("body>header").addClass("with-sub").after(subHeader);
        var content=$("aside.clist").html();
        subHeader.append(content);
        if (!subHeader.find(">ul>li.active").length) {
          var currentPage=$("<li />", {"class": "active"});
          var currentLink=$("<a />", {href: location.href, html: $(".page-content>h1, .page-content h2").html()}).appendTo(currentPage);
          subHeader.find(">ul").prepend(currentPage);
        }
        subHeader.click(function(e) {
          if (subHeader.hasClass("open"))
            return;
          e.preventDefault();
          subHeader.addClass("open");
        });
      }
    }
    
  }).trigger("resize.eusluga");
  
  
  $(".side-split>.clist>ul>li>a").each(function() {
    var link=$(this);
    var ul=$(this).parent().find(">ul");
    var uls=$(this).parent().parent().find(">li>ul");
    if (!ul.length)
      return;
    $(this).click(function(e) {
      e.preventDefault();
      uls.each(function() {
        if (this!=ul[0])
          $(this).slideUp(300);
      });
      ul.slideToggle(300);
    });
    
  });
  
  
  $(".share-btns a").click(function(e) {
    e.preventDefault();
    if ($(this).hasClass("fb")) {
      var url="https://www.facebook.com/sharer/sharer.php?u="+encodeURIComponent(location.href);
      window.open(url, "fbshare", "width=450,height=300");
    }
    if ($(this).hasClass("tw")) {
      var url="https://twitter.com/intent/tweet?text="+encodeURIComponent(document.title)+"&url="+encodeURIComponent(location.href);
      window.open(url, "fbshare", "width=450,height=300");
    }
  });
  
  
  $(".container>aside").each(function() {
    if (!$(this).find(">.aside-sticky").length)
      $(this).wrapInner("<div class=\"aside-sticky\" /></div>");
  });

  $(".container>aside").each(function () {
    if ($(this).find(">.aside-sticky>ul.single").length) {
        $(window).scroll(function () {
            if ($(window).scrollTop() > $(document).height() / 2) {
                $("div.aside-sticky").addClass('sticky-order');
            } else {
                $("div.aside-sticky").removeClass('sticky-order');
            }
        });
    }
});
  
});


/*********** CSS feature detection */
var positionStickySupport = function() {
  var el=document.createElement('a'),
      mStyle=el.style;
  mStyle.cssText="position:sticky;position:-webkit-sticky;position:-ms-sticky;";
  return mStyle.position.indexOf('sticky')!==-1;
}();

if (!positionStickySupport)
  jQuery("html").addClass("no-sticky");



